<template>
  <div class="container py-5">
    <h2>Da ist wohl etwas schief gelaufen ...</h2>
    <div class="mt-4">
      Deine Anfrage konnte leider nicht verarbeitet werden. Bitte kehre in die MyPute App zurück
      und probiere es erneut.
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
</style>
