import Vue from 'vue'
import App from './App.vue'
import "bootstrap/dist/js/bootstrap.min";
import router from "@/routes";
import "@/firebase";

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
