<template>
  <div class="container text-center py-5">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="py-5">
      Deine Anfrage wird verarbeitet ...
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.spinner-border {
  color: #789B07;
}
</style>
