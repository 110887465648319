<template>
  <div>
    <Navbar></Navbar>
    <Spinner v-if="requestVerified === null"></Spinner>
    <InvalidRequest v-else-if="requestVerified === false"></InvalidRequest>
    <PasswordReset :code="this.$route.query.oobCode" v-else></PasswordReset>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Spinner from "@/components/Spinner";
import InvalidRequest from "@/components/InvalidRequest";
import PasswordResetService from "@/service/password-reset-service";
import PasswordReset from "@/components/PasswordReset";

export default {
  components: {PasswordReset, InvalidRequest, Spinner, Navbar},
  data() {
    return {
      requestVerified: null,
    }
  },
  async mounted() {
    if (!this.$route.query.mode || !this.$route.query.oobCode) {
      this.requestVerified = false;
      return;
    }

    if (this.$route.query.mode !== "resetPassword") {
      this.requestVerified = false;
      return;
    }

    const passwordResetService = new PasswordResetService();
    const validOobCode = await passwordResetService.validateOobCode(this.$route.query.oobCode)
    if (!validOobCode) {
      this.requestVerified = false;
      return;
    }

    this.requestVerified = true;
  }
}
</script>

<style lang="scss">
@import "assets/main.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito', sans-serif;
}
</style>
