<template>
  <div class="navbar navbar-dark bg-green box-shadow">
    <div class="container d-flex justify-content-between">
      <a href="#" class="navbar-brand d-flex align-items-center">
        <img src="https://www.my-pute.de/wp-content/uploads/2020/06/logo-mypute-small.svg" alt="MyPute">
      </a>
    </div>
  </div>
</template>

<script>
export default  {}
</script>

<style lang="scss">
  .bg-green {
    background-color: #789B07 !important;
  }
</style>
