<template>
  <div class="container py-5">
    <h2>Passwort zurücksetzen</h2>

    <div v-if="resetSuccessful === null">
      <p>
        Bitte wähle hier ein neues Passwort bestehend mit einer Mindestlänge von sechs Zeichen.
      </p>

      <form class="py-4">
        <div class="form-group">
          <label for="newPassword">Neues Passwort:</label>
          <input type="password" class="form-control" id="newPassword" placeholder="Passwort" v-model="password">
        </div>
        <div class="form-group">
          <label for="passwordConfirm">Passwort bestätigen:</label>
          <input type="password" class="form-control" id="passwordConfirm" placeholder="Passwort bestätigen" v-model="confirm">
        </div>
        <button type="button" class="btn btn-primary" :disabled="passwordIsValid === false" @click="updatePassword">Speichern</button>
      </form>
    </div>
    <div v-else-if="resetSuccessful === true">
      <p>
        Dein Passwort wurde erfolgreich gespeichert. Du kannst dieses Fenster nun schließen und dich mit deinem neuen
        Passwort wie gewohnt in der MyPute App anmelden.
      </p>
    </div>
    <div v-else>
      <p>
        Beim Zurücksetzen des Passworts ist leider ein technischer Fehler aufgetreten.
      </p>
    </div>
  </div>
</template>

<script>
import PasswordResetService from "@/service/password-reset-service";

export default {
  props: ["code"],
  data() {
    return {
      resetSuccessful: null,
      password: "",
      confirm: "",
    }
  },
  computed: {
    passwordIsValid() {
      return this.password.length >= 6 && this.password === this.confirm;
    }
  },
  methods: {
    async updatePassword() {
      if (!this.passwordIsValid) {
        return;
      }

      const service = new PasswordResetService();
      this.resetSuccessful = await service.updatePassword(this.code, this.password);
    }
  }
}
</script>

<style scoped>
</style>
