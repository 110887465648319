import firebase from "firebase/app";

export default class PasswordResetService {
    async validateOobCode(code) {
        try {
            await firebase.auth().verifyPasswordResetCode(code);
            return true;
        } catch {
            return false;
        }
    }

    async updatePassword(code, password) {
        try {
            await firebase.auth().confirmPasswordReset(code, password);
            return true;
        } catch {
            return false;
        }
    }
}
